@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul, ol {
    padding-top: 16px;
    list-style: disc;
    list-style-position: inside;
  }
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.fixed-position-on-mobile {
  position: fixed;
  bottom: 3.5rem;
  left: 50%;
  transform: translate(-50%, 50%);
}

::-webkit-scrollbar {
  width: 0px;
}

.bg-center-x {
  background-position-x: center;
}

.center-items {
  -webkit-align-items: start;
  align-items: center;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  display: none;
  padding: 16px;
  width: 350px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 8px;
  border: black 1px solid;
  position: absolute;
  z-index: 99;
  bottom: 30px;
  right: 0px; 

}

.tooltip:hover .tooltiptext {
  visibility: visible;
  display: block;
}

@media screen and (min-width: 1200px) {
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    width: 500px;
  }
}


